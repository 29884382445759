/*--------------------------------------------------------------
2. Spacing
----------------------------------------------------------------*/
.p0{
  padding: 0 !important;
}
@media screen and (min-width: 992px) {
  .st-height-b0 {
    height: 0px
  }

  .st-height-b5 {
    height: 5px
  }

  .st-height-b10 {
    height: 10px
  }

  .st-height-b15 {
    height: 15px
  }

  .st-height-b20 {
    height: 20px
  }

  .st-height-b25 {
    height: 25px
  }

  .st-height-b30 {
    height: 30px
  }

  .st-height-b35 {
    height: 35px
  }

  .st-height-b40 {
    height: 40px
  }

  .st-height-b45 {
    height: 45px
  }

  .st-height-b50 {
    height: 50px
  }

  .st-height-b55 {
    height: 55px
  }

  .st-height-b60 {
    height: 60px
  }

  .st-height-b65 {
    height: 65px
  }

  .st-height-b70 {
    height: 70px
  }

  .st-height-b75 {
    height: 75px
  }

  .st-height-b80 {
    height: 80px
  }

  .st-height-b85 {
    height: 85px
  }

  .st-height-b90 {
    height: 90px
  }

  .st-height-b95 {
    height: 95px
  }

  .st-height-b100 {
    height: 100px
  }

  .st-height-b105 {
    height: 105px
  }

  .st-height-b110 {
    height: 110px
  }

  .st-height-b115 {
    height: 115px
  }

  .st-height-b120 {
    height: 120px
  }

  .st-height-b125 {
    height: 125px
  }

  .st-height-b130 {
    height: 130px
  }

  .st-height-b135 {
    height: 135px
  }

  .st-height-b140 {
    height: 140px
  }

  .st-height-b145 {
    height: 145px
  }

  .st-height-b150 {
    height: 150px
  }

  .st-height-b155 {
    height: 155px
  }

  .st-height-b160 {
    height: 160px
  }

  .st-height-b165 {
    height: 165px
  }

  .st-height-b170 {
    height: 170px
  }

  .st-height-b175 {
    height: 175px
  }

  .st-height-b180 {
    height: 180px
  }

  .st-height-b185 {
    height: 185px
  }

  .st-height-b190 {
    height: 190px
  }

  .st-height-b195 {
    height: 195px
  }

  .st-height-b200 {
    height: 200px
  }

  .st-height-b205 {
    height: 205px
  }

  .st-height-b210 {
    height: 210px
  }
}

@media screen and (max-width: 991px) {
  .st-height-lg-b0 {
    height: 0px
  }

  .st-height-lg-b5 {
    height: 5px
  }

  .st-height-lg-b10 {
    height: 10px
  }

  .st-height-lg-b15 {
    height: 15px
  }

  .st-height-lg-b20 {
    height: 20px
  }

  .st-height-lg-b25 {
    height: 25px
  }

  .st-height-lg-b30 {
    height: 30px
  }

  .st-height-lg-b35 {
    height: 35px
  }

  .st-height-lg-b40 {
    height: 40px
  }

  .st-height-lg-b45 {
    height: 45px
  }

  .st-height-lg-b50 {
    height: 50px
  }

  .st-height-lg-b55 {
    height: 55px
  }

  .st-height-lg-b60 {
    height: 60px
  }

  .st-height-lg-b65 {
    height: 65px
  }

  .st-height-lg-b70 {
    height: 70px
  }

  .st-height-lg-b75 {
    height: 75px
  }

  .st-height-lg-b80 {
    height: 80px
  }

  .st-height-lg-b85 {
    height: 85px
  }

  .st-height-lg-b90 {
    height: 90px
  }

  .st-height-lg-b95 {
    height: 95px
  }

  .st-height-lg-b100 {
    height: 100px
  }

  .st-height-lg-b105 {
    height: 105px
  }

  .st-height-lg-b110 {
    height: 110px
  }

  .st-height-lg-b115 {
    height: 115px
  }

  .st-height-lg-b120 {
    height: 120px
  }

  .st-height-lg-b125 {
    height: 125px
  }

  .st-height-lg-b130 {
    height: 130px
  }

  .st-height-lg-b135 {
    height: 135px
  }

  .st-height-lg-b140 {
    height: 140px
  }

  .st-height-lg-b145 {
    height: 145px
  }

  .st-height-lg-b150 {
    height: 150px
  }

  .st-height-lg-b155 {
    height: 155px
  }

  .st-height-lg-b160 {
    height: 160px
  }

  .st-height-lg-b165 {
    height: 165px
  }

  .st-height-lg-b170 {
    height: 170px
  }

  .st-height-lg-b175 {
    height: 175px
  }

  .st-height-lg-b180 {
    height: 180px
  }

  .st-height-lg-b185 {
    height: 185px
  }

  .st-height-lg-b190 {
    height: 190px
  }

  .st-height-lg-b195 {
    height: 195px
  }

  .st-height-lg-b200 {
    height: 200px
  }

  .st-height-lg-b205 {
    height: 205px
  }

  .st-height-lg-b210 {
    height: 210px
  }
}